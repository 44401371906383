import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Baskerville, G.L. and Emin, P. 1969. Rapid Estimation of Heat
        Accumulation from Maximum and Minimum Temperatures. Ecology. 50:
        514-517.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Schmaedick, M. A., and J. P. Nyrop. 1993. Sampling second generation
        spotted tentiform leafminer: a means to reduce overall control costs and
        facilitate biological control of mites in apple orchards. NY Food and
        Life Science Bulletin No. 143, 4 pp.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Schmaedick, M. A., and J. P. Nyrop. 1995. Method for sampling arthropod
        pests with uncertain phenology with application to spotted tentiform
        leafminer (Lepidoptera: Gracillariidae). J. Econ. Entomol. 88: 875-889.
      </p>
    </>
  )
}
