import GlobalStateContext from "../../context/globalStateContext"
import { Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"

export default function MoreInfo({ moreInfo }) {
  const { station, DEFAULT_STATION } = React.useContext(GlobalStateContext)

  return (
    <>
      <p className="text-sm">
        Be aware that the apple insect models were developed for New York State
        conditions and might not accurately reflect insect activity in other
        locations.
      </p>
      <ul className="px-4 mt-6 text-sm text-left list-disc">
        {moreInfo
          .filter(
            (d) =>
              d.statesOrProvinces.includes("ALL") ||
              d.statesOrProvinces.includes(
                station ? station.state : DEFAULT_STATION.state
              )
          )
          .map((d) => {
            return (
              <li key={d.url} className="mb-4">
                <a href={d.url} target="_blank" rel="noopener noreferrer">
                  {parse(d.title)}
                </a>
              </li>
            )
          })}

        <li className="mb-4">
          <Link
            to="/default-biofix-dates"
            aria-label="NEWA Default Biofix Dates"
          >
            NEWA Default Biofix Dates
          </Link>
        </li>
      </ul>
    </>
  )
}
